import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Importer } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { ArchiveModal, ContextMenu, TableColumnHeader, toast } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow {
  original: Importer;
}

const OptionsCell = observer(({ original }: IRow) => {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const isComplete = original.get('state') === 'complete';
  const isDraft = original.get('state') === 'draft';
  const isError = original.get('errors_key');

  const [isOpenModal, { open: openModal, close: closeModal }] = useDisclosure(false);

  const { Importers } = useStore();

  async function handleClickDelete(): Promise<void> {
    try {
      await original.deleteImport(original.id);
      Importers.remove(original.id);
      toast(t('Import deleted successfully.'), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(t('Something went wrong while trying to delete this import: {{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    } finally {
      closeModal();
    }
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item
          disabled={!isComplete}
          link={localUrl(`/data/segments/${original.get('segment')}/people`)}
          type={'link'}
        >
          {t('View Contacts')}
        </ContextMenu.Item>
        <ContextMenu.Item disabled={isDraft} href={original.downloadUrl} type={'link'}>
          {t('Download File')}
        </ContextMenu.Item>
        {isError && (
          <ContextMenu.Item href={original.downloadErrorUrl} type={'link'}>
            {t('Download Errors')}
          </ContextMenu.Item>
        )}
        {isDraft && (
          <ContextMenu.Item
            onClick={openModal}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Delete')}
          </ContextMenu.Item>
        )}
      </ContextMenu>
      <ArchiveModal
        alertDescription={t(
          'Deleting this import will remove it from the list of imports.  This action cannot be undone.',
        )}
        alertTitle={t('Are you sure you want to delete this import?')}
        cancelButtonText={t('Cancel')}
        confirmationPhrase={t('DELETE')}
        confirmButtonText={t('Delete')}
        confirmButtonType={'danger'}
        onClose={closeModal}
        onConfirm={handleClickDelete}
        opened={isOpenModal}
        t={t}
        title={t('Delete "{{- name}}"', { name: original.name })}
      />
    </>
  );
});

export default (): IColumn<Importer> => ({
  id: 'options',
  checkboxLabel: 'Options',
  Header: TableColumnHeader({
    title: 'Options',
  }),
  width: 80,
  sortable: false,
  className: tableStyles.cellCenter,
  Cell(row): JSX.Element {
    return <OptionsCell {...row} />;
  },
});
